import { default as emailsCMu3esDLiWMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/emails.vue?macro=true";
import { default as integrationsiE5JH0vhidMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/integrations.vue?macro=true";
import { default as overviewreRzUUUXVLMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/overview.vue?macro=true";
import { default as indexqrPLLRi3jZMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsldG6QiTXjOMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexdu2gfT5JBkMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/index.vue?macro=true";
import { default as indexEYVJWX4HyUMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/[id]/index.vue?macro=true";
import { default as addKpLcVbV2MKMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/add.vue?macro=true";
import { default as indexizxW16pOKTMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/index.vue?macro=true";
import { default as adminwnwjrjBurwMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin.vue?macro=true";
import { default as accountyoKZaFHJoHMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/account.vue?macro=true";
import { default as indexPJ1mSRNjZjMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/archive/index.vue?macro=true";
import { default as entouragepA8rOsaqB8Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsUzDz9xLOtnMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthZThaaBblIpMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as info1Qw87LUOvsMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationezahaUC3x5Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45households4wwEiQeUXMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printLqbpvXrcD6Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexdDnyjrfGC0Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/index.vue?macro=true";
import { default as editnqH3PgdTdsMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editwXzhoN7a6qMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as edit1FBgKXFoMdMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addYBz4qckXSWMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editPip2K7ZyCmMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addQfYvx3zEyXMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editxGw3DqhYW6Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addSwCrDuOHKWMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as edit9FY1Mwv2e2Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexw3KoRTA35tMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/index.vue?macro=true";
import { default as addjKGZRqHQeTMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/add.vue?macro=true";
import { default as historyS4ZlgDCOHXMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/history.vue?macro=true";
import { default as printitSZfKBaOtMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewJcno9pCsBfMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/overview.vue?macro=true";
import { default as partnersxhho9raFZDMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/partners.vue?macro=true";
import { default as statskkVZuqy5AIMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/stats.vue?macro=true";
import { default as index79qkmfQcr0Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/structure/index.vue?macro=true";
import { default as subjects8qL1aELOOeMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/structure/subjects.vue?macro=true";
import { default as usersHu8qywJhffMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/users.vue?macro=true";
import { default as appV9IKnhjcW8Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app.vue?macro=true";
import { default as admin1CjJqWkot2Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/admin.vue?macro=true";
import { default as loginYcvVX3zmzVMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/login.vue?macro=true";
import { default as logoutxbVNtGQGXWMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/logout.vue?macro=true";
import { default as validate_45tokenBAaTLlgGHSMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/validate-token.vue?macro=true";
import { default as managerglClPsilDzMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu/manager.vue?macro=true";
import { default as userlduEDN9lkzMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu/user.vue?macro=true";
import { default as cgueexzswZ9kAMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitedatgAEoFKIMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45account8DjsGkws18Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokenK0Ki5uC1O5Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45icXLfmJYJ1YrMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45userFaatcM0aYOMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/unknown-user.vue?macro=true";
import { default as indexXWN4PFRuIBMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/index.vue?macro=true";
import { default as mentions_45legalesQjpVmigOVoMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteo9I36rYSQjMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/politique-confidentialite.vue?macro=true";
import { default as registerYEOgUbspT1Meta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/register.vue?macro=true";
import { default as statistiquesJoL4y2D3skMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/statistiques.vue?macro=true";
import { default as stories2URM5mwCXPMeta } from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminwnwjrjBurwMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/integrations.vue")
  },
  {
    name: overviewreRzUUUXVLMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewreRzUUUXVLMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appV9IKnhjcW8Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/account.vue")
  },
  {
    name: indexPJ1mSRNjZjMeta?.name ?? "app-beneficiaries-id-archive",
    path: "beneficiaries/:id()/archive",
    meta: indexPJ1mSRNjZjMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/archive/index.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupationezahaUC3x5Meta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationezahaUC3x5Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printLqbpvXrcD6Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexdDnyjrfGC0Meta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexdDnyjrfGC0Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/beneficiaries/index.vue")
  },
  {
    name: editnqH3PgdTdsMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editnqH3PgdTdsMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editwXzhoN7a6qMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editwXzhoN7a6qMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: edit9FY1Mwv2e2Meta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: edit9FY1Mwv2e2Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printitSZfKBaOtMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewJcno9pCsBfMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewJcno9pCsBfMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: admin1CjJqWkot2Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginYcvVX3zmzVMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutxbVNtGQGXWMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenBAaTLlgGHSMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cgueexzswZ9kAMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitedatgAEoFKIMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45account8DjsGkws18Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokenK0Ki5uC1O5Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45icXLfmJYJ1YrMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userFaatcM0aYOMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexXWN4PFRuIBMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesQjpVmigOVoMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteo9I36rYSQjMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerYEOgUbspT1Meta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesJoL4y2D3skMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: stories2URM5mwCXPMeta || {},
    component: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/pages/stories.vue")
  }
]