import validate from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/build/cbbe983d-416e-4b62-84f2-a785c4a42333/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/middleware/auth.ts"),
  cgu: () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/middleware/cgu.ts"),
  "skip-auth": () => import("/build/cbbe983d-416e-4b62-84f2-a785c4a42333/middleware/skip-auth.ts")
}